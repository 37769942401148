import React, { useState } from "react"
import Accordion from "react-bootstrap/Accordion"
import { useAccordionButton } from "react-bootstrap/AccordionButton"

const CustomToggle = ({ children, eventKey }) => {
  const decoratedOnClick = useAccordionButton(eventKey, () => {
    setShow(false)
  })

  const [show, setShow] = useState(true)
  return (
    <button
      className={`btn btn-lg btn-outline-light ${show ? "" : "d-none"}`}
      type="button"
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  )
}

const LearnMore = () => {
  return (
    <Accordion className="mb-65">
      <CustomToggle eventKey="0">Learn More</CustomToggle>
      <Accordion.Collapse eventKey="0">
        <p className="color-white intro-more">
          Founded in 1999, GOTHAM has a thorough understanding of value added
          opportunities throughout various regions in the United States. GOTHAM,
          strategically supports the transformation of previously blighted,
          physically deteriorated neighborhoods into thriving commercial and
          residential communities with increased value and positive community
          growth. GOTHAM invests and manages a portfolio of stabilized trophy
          assets consisting primarily of commercial urban mixed use product,
          retail properties, and residential properties. It's long term vision
          for acquisition, development, and management building long term value.
        </p>
      </Accordion.Collapse>
    </Accordion>
  )
}

export default LearnMore
