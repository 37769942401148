import * as React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import "@styles/pages/index.scss"
import LearnMore from "@src/components/home/ExpandingText"

const IndexPage = ({
  data: {
    allWpProperty: { nodes: properties },
  },
}) => (
  <Layout>
    <Seo title="Gotham" />
    <section>
      <StaticImage src={`../images/hero.png`} alt="" />
    </section>
    <section className="bg-primary text-center">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <p className="intro">
              GOTHAM family of companies are a privately held real estate
              investment firm specializing in commercial and residential real
              estate acquisitions and property management.
            </p>

            <LearnMore />
            <hr
              style={{
                borderColor: "white",
                margin: "0",
                borderTopWidth: "2px",
              }}
            />
          </div>
        </div>
      </div>
    </section>
    <section className="bg-primary text-center">
      <div className="container">
        <h2 className="properties-title">
          Properties Under
          <br />
          Ownership &amp; Management
        </h2>
        <div className="d-flex flex-wrap">
          <div className="col">
            {properties.map((property, index) => (
              <div className="d-md-flex" key={index}>
                <div
                  className={`col-12 col-md-6 ${
                    index % 2 === 0 ? "" : "order-md-1"
                  }`}
                >
                  <GatsbyImage
                    image={
                      property.featuredImage.node.localFile.childImageSharp
                        .gatsbyImageData
                    }
                    alt=""
                  />
                </div>

                <div
                  className={`col-12 col-md-6 d-flex flex-column justify-content-center align-items-center color-white property-text-wrap ${
                    index === properties.length - 1 ? "last" : ""
                  }`}
                >
                  <p
                    className="property-usage"
                    dangerouslySetInnerHTML={{
                      __html: property.propertyMetadata.usageType,
                    }}
                  />
                  <h3 className="property-title">{property.title}</h3>
                  <p className="property-address">
                    {property.propertyMetadata.address}
                  </p>
                  {property.propertyMetadata.callToActionButton && (
                    <a
                      className="btn btn-outline-light"
                      target={
                        property.propertyMetadata.callToActionButton.target
                      }
                      href={property.propertyMetadata.callToActionButton.url}
                    >
                      {property.propertyMetadata.callToActionButton.title}
                    </a>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query IndexPageQuery {
    allWpProperty {
      nodes {
        title
        propertyMetadata {
          usageType
          address
          callToActionButton {
            target
            title
            url
          }
        }
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  height: 470
                  width: 470
                  transformOptions: { cropFocus: CENTER }
                )
              }
            }
          }
        }
      }
    }
  }
`
